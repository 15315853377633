import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@/assets/css/global.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import VueGtag from 'vue-gtag';

const trackingId = 'G-2419W8XT7V';

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    created() {
        AOS.init();
    },
    use: [
        [VueGtag, {
            config: {id: trackingId}
        }]
    ],
}).$mount('#app')
